import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { alvTokens } from '@dasa-health/alma-tokens'
import { 
  PublicationAuthor, 
  PublicationAuthorImg, 
  PublicationAuthorImgLink, 
  PublicationAuthorName, 
  PublicationAuthorNameLink 
} from '../../single-clinical-staff/styled'

export const BlogCardWrapper = styled.div`
  width: 50.3rem;
  padding: 1.6rem;
  background: var(--neutral00);
  border-radius: 4px;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: calc(100vw - 3.2rem);
    height: ${props => props.img ? '44.8rem' : '27.8rem'};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`

export const BlogCardTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2.4rem 0;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: .8rem;
    margin: 1.6rem 0;
  `}
`

export const BlogCardCategories = styled.p`
  color: var(--pink);
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--font12);
  line-height: 133%;
  max-width: 35rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 1.7rem;
  cursor: ${props => props.setCategories ? 'pointer' : 'default'};
`

export const BlogCardLink = styled(Link)`
  text-decoration: none;
`

export const BlogCardTitle = styled.h2`
  font-size: var(--font24);
  font-weight: 700;
  color: ${props => props.theme.colors.brand.primary[10]};
  line-height: 133%;
  margin-bottom: 2.4rem;
  min-height: 9.572rem;

  ${media.lessThan('medium')`
    min-height: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 1.6rem;
    font-size: var(--font20);
  `}
`

export const BlogAuthorImg = styled(Img)``

export const BlogAuthor = styled(PublicationAuthor)``
export const BlogLink = styled(PublicationAuthorImgLink)``
export const BlogImage = styled(PublicationAuthorImg)`
  ${media.lessThan('medium')`
    width: 3.2rem;
    height: 3.2rem;
  `}
`
export const BlogAuthorLink = styled(PublicationAuthorNameLink)``
export const BlogAuthorName = styled(PublicationAuthorName)``
export const BlogImageSvg = styled.div`
  width: 3.2rem;
  height: 3.2rem;
`

export const BlogCardDate = styled.p`
  font-size: 12px;
  color: ${alvTokens.ColorPaletteGray70};
  line-height: 133%;
`

export const BlogPostLink = styled(Link)`
  text-decoration: none;
`

export const BlogPostContent = styled.p`
 p {
    font-size: var(--font16);
    line-height: 150%;
    height: 7.2rem;
    font-weight: 400;
    color: ${alvTokens.ColorPaletteGray70};
    margin-bottom: 2.4rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${media.lessThan('medium')`
      font-size: var(--fontDefault);
      height: 6rem;
      -webkit-line-clamp: 3;
      margin-bottom: 1.6rem;
    `}
  }
`
